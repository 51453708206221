import axios from "axios";
import { BACKEND_API_URL } from "../config";
import { getAccessToken } from "../auth";

export class Apis {
  static setAuthToken(token) {
    if (token) {
      // console.log("set header token", token);
      axios.defaults.headers.common["Authorization"] = `${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }

  static async _getCall(path) {
    try {
      const res = await axios.get(`${BACKEND_API_URL}${path}`);
      return { success: true, data: res.data };
    } catch (e) {
      if (e.response?.status !== 404) {
        let message = e.response.data.message;
        if (Array.isArray(message)) {
          message = message[0];
        }
        return { success: false, error_message: message };
      } else {
        console.error("Backend API call failed:", e);
      }
    }
    return { success: false, error_message: "Can not connect to server!" };
  }

  static async _postCall(path, payload) {
    try {
      const res = await axios.post(`${BACKEND_API_URL}${path}`, payload);
      return { success: true, data: res.data };
    } catch (e) {
      if (e.response?.status !== 404) {
        let message = e.response.data.message;
        if (Array.isArray(message)) {
          message = message[0];
        }
        return { success: false, error_message: message };
      } else {
        console.error("Backend API call failed:", e);
      }
    }
    return { success: false, error_message: "Can not connect to server!" };
  }

  /**
   * login
   * /api/auth/login
   * **/
  static async signIn(username, password) {
    const path = `/user/login`;
    return this._postCall(path, { username, password });
  }

  static async me() {
    const path = `/user/me`;
    return this._getCall(path);
  }

  static async getConfig() {
    const path = `/config`;
    return this._getCall(path);
  }

  static async updateConfig(payload) {
    const path = `/config/update`;
    return this._postCall(path, payload);
  }

  static async getLink(linkName){
    const path = `/link?linkName=${linkName}`;
    return this._getCall(path);
  }

  static async getLinks(page) {
    const path = `/link/list?page=${page}`;
    return this._getCall(path);
  }

  static async generateLink(payload){
    const path = '/link/generate';
    return this._postCall(path, payload);
  }

  static async deleteLink(payload){
    const path = '/link/delete';
    return this._postCall(path, payload);
  }

  static async setWin(payload) {
    const path = '/link/win';
    return this._postCall(path, payload);
  }

  static async uploadImage(formData){
    const path = '/config/upload';
    return this._postCall(path, formData)
  }
}

const accessToken = getAccessToken();
if (accessToken) {
  // axios set bearer token
  Apis.setAuthToken(accessToken);
}
