import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Apis } from "../api";
import { useAuthContext } from "../auth/context";
import Loader from "../components/Loader";
import { CircularProgress } from "@mui/material";

function Login() {
  const {
    loading: AuthLoading,
    user,
    setUserInfo,
  } = useAuthContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    if (user) {
      navigate("/admin");
    }
  }, [user]);

  const handleLogin = async (event) => {
    event.preventDefault();

    setSubmitting(true);
    setError("");

    const response = await Apis.signIn(username.toLowerCase(), password);
    if (response.success) {
      if (response.data.success) {
        // Login successful, save token to localStorage or handle as needed
        setUserInfo(response.data.data);
      } else {
        setSubmitting(false);
        setError(response.data.message);
      }
    } else {
      setSubmitting(false);
      setError(response.error_message);
    }
  };

  if (AuthLoading) {
    return (
      <div className="flex w-full min-h-screen items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white w-full xl:w-4/12 mb-20">
        <h1 className="mx-auto text-center text-2xl font-bold font-primary mt-5">
          Sign In
        </h1>

        <form onSubmit={handleLogin}>
          <div className="mb-6 w-10/12 mx-auto mt-10">
            <label
              htmlFor="username"
              className="block mb-2 text-sm font-semibold text-gray-900 dark:text-white"
            >
              Username
            </label>
            <input
              type="username"
              id="username"
              className="bg-[#F1F4FA] py-4 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Username"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="mb-6 w-10/12 mx-auto mt-5">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-semibold text-gray-900 dark:text-white"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="bg-[#F1F4FA] py-4 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="xxxxxxxxxx"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="flex w-10/12 mx-auto">
            <button
              type="submit"
              className="flex items-center justify-center rounded-md border border-transparent bg-blue-700 w-full py-3 font-medium text-white hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              disabled={submitting}
            >
              {submitting ? (
                <CircularProgress
                  size={24}
                  disableShrink
                  sx={{ color: "white" }}
                />
              ) : (
                "Login"
              )}
            </button>
          </div>
        </form>

        {error && (
          <p className="text-red-500 mt-2 text-sm text-center">{error}</p>
        )}
      </div>
    </div>
  );
}

export default Login;
