import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import { useAuthContext } from "../../auth/context";
import AdminSidebar from "./AdminSidebar";
import { useLocation } from "react-router-dom";
import { Drawer, IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";

function Layout({ child }) {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { loading, user } = useAuthContext();
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    if (!loading) {
      if (!user) {
        window.location.href = "/login";
        return;
      }

      const isAdminRoute = location.pathname.indexOf("/admin") === 0;

      if (!isAdminRoute) {
        window.location.href = "/admin";
        return;
      }

      setIsLoading(false);
    }
  }, [user, loading]);

  if (isLoading || loading) {
    return (
      <div className="flex w-full min-h-screen items-center justify-center">
        <Loader />
      </div>
    );
  }
  const onCloseDrawer = () => setShowDrawer(false);

  return (
    <div>
      <div className="flex max-w-[2048px] mx-auto">
        <div className="h-screen sticky top-0 hidden xl:block">
          <AdminSidebar />
        </div>
        <Drawer
          anchor="left"
          open={showDrawer}
          onClose={onCloseDrawer}
        >
          <AdminSidebar />
        </Drawer>
        <div className="bg-[#F7F7F8] w-full overflow-auto dark:bg-darkwhite-100">
          <main className="sm:px-2 min-h-screen overflow-hidden">
            <div className="text-right">
              <div className="dark:bg-darkwhite-90 rounded-2xl xl:hidden inline-block">
                <IconButton
                  aria-label="toggle"
                  onClick={() => setShowDrawer(true)}
                  sx={{
                    padding: "16px",
                  }}
                >
                  <Menu />
                </IconButton>
              </div>
            </div>
            {child}
          </main>
        </div>
      </div>
    </div>
  );
}

export default Layout;
