import Wheel from "../components/wheel";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Apis} from "../api";
import * as party from "party-js";
import {Dialog, Slide} from "@mui/material";

const Transition = React.forwardRef(function Transition(
    props,
    ref,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Play() {
    const location = useLocation();
    const [linkId, setLinkId] = useState(null);
    const [segments, setSegments] = useState([]);
    const [align, setAlign] = useState("center");
    const [textRadius, setTextRadius] = useState(10);
    const [iconRadius, setIconRadius] = useState(90);
    const [labelSize, setLabelSize] = useState(20);
    const [iconWidth, setIconWidth] = useState(30);
    const [iconHeight, setIconHeight] = useState(30);
    const [eachLine, setEachLine] = useState(20);
    const [winDlgText, setWinDlgText] = useState("");
    const [link, setLink] = useState(null);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [win, setWin] = useState("");
    const [reload, setReload] = useState(false);

    useEffect(() => {
        const linkName = location.pathname.replace("/", "");

        if (linkName) {
            setLinkId(linkName);
            fetchLinkInfo(linkName).then();
        } else {
            setLoading(false);
        }

        Apis.getConfig().then((res) => {
            if (res.success && res.data.success) {
                const config = res.data.data;
                setSegments(config.segments);
                setAlign(config.align);
                setTextRadius(config.textRadius);
                setIconRadius(config.iconRadius);
                setLabelSize(config.labelSize);
                setIconWidth(config.iconWidth);
                setIconHeight(config.iconHeight);
                setWinDlgText(config.winDlgText);
                setEachLine(config.eachLine ?? 20);
            }
            setReload(!reload);
        });
    }, []);

    const fetchLinkInfo = async (linkName) => {
        const res = await Apis.getLink(linkName);
        if (res.success && res.data.success) {
            setLink(res.data.link);
            return res.data.link;
        }
        setLoading(false);
        return null
    }

    const onFinished = async (win) => {
        if (win) {
            if (linkId && link.max > link.done) {
                Apis.setWin({linkName: linkId, win}).then(async () => {
                    const linkInfo = await fetchLinkInfo(linkId);
                    if (linkInfo.max === linkInfo.done) {
                        setTimeout(() => setOpen(true), 1000);
                    }
                });
            }

            for (let i = 0; i < 5; i++) {
                party.confetti(party.Rect.fromScreen(), {
                    count: party.variation.range(20, 40),
                });
            }
        } else {
            const latestWin = link.wins[link.wins.length - 1];
            setWin(latestWin);
            setOpen(true);
        }
    }

    const reloadWheel = () => {
        const old = Object.assign([], segments);
        setSegments([]);
        setTimeout(() => setSegments(old), 10);
    }

    return (
        <>
            <div className={"App bg-[#161618] relative" + (!loading && !link ? " blur-sm" : "")}>
                <div className="mx-auto max-w-2xl flex items-center justify-center min-h-screen ">

                    <div className="bg-blue-100 rounded-xl">
                        <div className="py-4 px-4 sm:px-8">
                            <div className="text-2xl font-bold">Wheel</div>
                            <div className="mt-2 text-xl italic">{link ? link.linkName : ""}</div>
                        </div>
                        <div className="flex bg-white py-4 sm:px-12 sm:py-8 gap-8 flex-wrap sm:flex-nowrap">
                            {
                                segments.length > 0 &&
                                <div className="w-full">
                                    <div style={{display: "none"}}>
                                        {
                                            segments.map((s, index) => <img key={index} id={`segment-image-${index}`}
                                                                            src={s.imageUrl}
                                                                            alt="" width={30} height={30}/>)
                                        }
                                    </div>
                                    <Wheel
                                        segments={segments}
                                        onFinished={(winner) => onFinished(winner)}
                                        primaryColor='black'
                                        isOnlyOnce={false}
                                        iconWidth={iconWidth}
                                        iconHeight={iconHeight}
                                        upDuration={50}
                                        downDuration={120}
                                        fontFamily='Arial'
                                        fontSize={labelSize}
                                        textColor='white'
                                        align={align}
                                        textRadius={textRadius}
                                        imageRadius={iconRadius}
                                        reload={reload}
                                        disabled={!link}
                                        isFull={link && link.max === link.done}
                                        eachLine={eachLine}
                                    />
                                </div>
                            }
                            <div className="sm:min-w-64 h-full w-full px-2">
                                <div className="border-2 rounded-xl py-4 px-2">
                                    <table className="w-full">
                                        <thead>
                                        <tr className="border-b-2">
                                            <th className="w-[30px]">No</th>
                                            <th className="text-center">Win</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            link &&
                                            link.wins.map((w, index) =>
                                                <tr key={index}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td className="text-center">{w}</td>
                                                </tr>)
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => {
                            setOpen(false);
                            reloadWheel();
                        }}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <div className="px-6 mt-8">
                            <span className="text-xl" dangerouslySetInnerHTML={{__html: winDlgText.replaceAll("\n", "<br/>")}}></span>
                        </div>
                        <div className="my-6 w-full">
                            <button className="btn-grad" onClick={() => {
                                setOpen(false);
                                reloadWheel();
                            }}>OK</button>
                        </div>
                    </Dialog>
                </div>
            </div>
            {
                !loading && !link &&
                <div className="absolute left-0 top-0 right-0 bottom-0 flex items-center justify-center z-[10000]">
                    <div className="max-w-xl opacity-80 bg-gray-200 w-full mx-4 rounded-xl text-xl px-4 py-4">
                        [ENG]<br/>
                        To spin the wheel, follow the instructions on my OnlyFans.<br/><br/>
                        [RO]<br/>
                        Pentru a juca la roată, urmează instrucțiunile de pe OnlyFans-ul meu.
                    </div>
                </div>
            }
        </>
    );
}

export default Play;
