export const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

export const removeAccessTokenFromStorage = () => {
  localStorage.removeItem("access_token");
};

export const setAccessTokenToStorage = (accessToken) => {
  localStorage.setItem("access_token", accessToken);
};
