import {ChromePicker} from "react-color";
import {useState} from "react";

export default function ColorPicker({ color, onChangeColor }) {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const popover = {
        position: 'absolute',
        zIndex: '2',
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    return (
        <div>
            <div className="h-[36px] w-[80px] border-2" style={{ backgroundColor: color }} onClick={ () => setDisplayColorPicker(true) }></div>
            { displayColorPicker ? <div style={ popover }>
                <div style={ cover } onClick={ () => setDisplayColorPicker(false) }/>
                <ChromePicker color={color} onChange={(v) => onChangeColor(v.hex)} />
            </div> : null }
        </div>
    );
}