import React from "react";
import Router from "./routes";
import {AuthProvider} from "./auth/context";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <AuthProvider>
            <Router/>
            <ToastContainer/>
        </AuthProvider>
    );
}

export default App;