export const FileUpload = async (file) => {
    const cloud_name = "dvebzo4j5";
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "mxhvijym");
    formData.append('cloud_name', cloud_name);

    try {
        const res = await fetch('https://api.cloudinary.com/v1_1/dvebzo4j5/auto/upload', {
            method: 'POST',
            body: formData
        });
        const data = await res.json();
        return data.secure_url;
    } catch (err) {
        console.log(err)
        return null;
    }
}
