import { Link } from "react-router-dom";
import React, { FC } from "react";

const SidebarItem = ({ currentPath, path, label, icon }) => {
  const isActive = currentPath.indexOf(path) === 0;
  return (
    <div className="flex mt-10">
      <Link
        className={`flex w-full ${
          isActive
            ? "text-[#3A36DB] dark:text-[#6d84ff]"
            : "text-black dark:text-white"
        } hover:text-[#3A36DB] hover:cursor-pointer`}
        to={path}
      >
        {icon}
        <h1 className="text-xl font-bold font-primary ml-1">{label}</h1>
      </Link>
    </div>
  );
};

export default SidebarItem;
