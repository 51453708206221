import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

// pages
import Login from "../pages/Login";

// admin pages
import Home from "../pages/admin/Home";
import LinkList from "../pages/admin/LinkList";

// client pages
import Play from "../pages/Play";
import Layout from "../components/Layout/Layout";

const AdminRoutes = {
  "/admin": <Home />,
  "/admin/links": <LinkList />,
};

function Index() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />

        {Object.keys(AdminRoutes).map((path) => (
          <Route
            key={path}
            path={path}
            element={<Layout child={AdminRoutes[path]} />}
          />
        ))}

        <Route path="/" element={<Play />} />
        <Route path="/:name" element={<Play />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Index;
