import {useEffect, useRef} from "react";
import {randomString} from "../utils/common";

const NORMAL_SIZE = 280;
const getValueBasedOnNormal = (size, value) => {
    return Math.floor(value * size / NORMAL_SIZE);
}

let angleCurrent = 0;

const Wheel = (
    {
        segments,
        onFinished,
        primaryColor = "black",
        iconWidth = 30,
        iconHeight = 30,
        isOnlyOnce = true,
        size = Math.min(Math.floor((window.innerWidth - 40) / 2), 280),
        upDuration = 100,
        downDuration = 1000,
        fontFamily = "proxima-nova",
        fontSize = 12,
        textColor = "white",
        align="center",
        textRadius = 20,
        imageRadius = 90,
        eachLine= 20,
        reload = false,
        disabled = false,
        isFull = false,
    }) => {

    const canvasId = useRef(`canvas-${randomString()}`);
    const wheelId = useRef(`wheel-${randomString()}`);
    const dimension = (size + 20) * 2;
    const canvasDimension = dimension * 2;
    const canvasSize = size * 2
    let currentSegment = "";
    let currentSegmentIndex = 0;
    let isStarted = false;
    let timerHandle = 0;
    const timerDelay = 10;
    let angleDelta = 0;
    let canvasContext = null;
    let maxSpeed = Math.PI / segments.length;
    const upTime = segments.length * upDuration;
    const downTime = segments.length * downDuration;
    let frames = 0;
    const centerX = canvasDimension / 2;
    const centerY = canvasDimension / 2;
    let winningSegmentIndex = 0;
    const _imageRadius = getValueBasedOnNormal(canvasSize, imageRadius);
    const _textRadius = getValueBasedOnNormal(canvasSize, textRadius);
    const _fontSize = getValueBasedOnNormal(canvasSize, fontSize);

    const audio = new Audio("/sounds/tick_2.mp3");
    audio.volume = 0.3;

    useEffect(() => {
        setTimeout(() => {
            wheelInit();
            window.scrollTo(0, 1);
        }, 100);
    }, [segments, textRadius, imageRadius, textColor, fontSize, align, iconWidth, iconHeight, eachLine, reload]);

    const setWinSegment = () => {
        let totalP = 0;
        const sumS = [];
        for(let i = 0; i < segments.length; i++) {
            const s = segments[i];
            if(!s.noSelect) {
                totalP += s.percent ?? 0;
                sumS.push({index: i, ptop: totalP});
            }
        }


        const rp = Math.floor(Math.random() * totalP) % totalP;

        for(const s of sumS) {
            if (rp < s.ptop) {
                winningSegmentIndex = s.index;
                // console.log("winning", winningSegmentIndex);
                return;
            }
        }
    }

    const wheelInit = () => {
        loadImages();
        initCanvas();
        wheelDraw();
    };

    const images = [];

    const loadImages = () => {
        for (const index in segments) {
            const image = document.getElementById(`segment-image-${index}`);
            if (image) {
                images[Number(index)] = image;
            }
        }
    }

    const initCanvas = () => {
        let canvas = document.getElementById(canvasId.current);
        if (navigator.userAgent.indexOf("MSIE") !== -1) {
            canvas = document.createElement("canvas");
            canvas.setAttribute("width", `${dimension}`);
            canvas.setAttribute("height", `${dimension}`);
            canvas.setAttribute("id", canvasId.current);
            document.getElementById(wheelId.current)?.appendChild(canvas);
        }

        canvas.width = canvasDimension;
        canvas.height = canvasDimension;
        canvas.style.width = dimension + 'px';
        canvas.style.height = dimension + 'px';
        // canvas?.addEventListener("click", spin, false);
        canvasContext = canvas.getContext("2d");
    };
    const spin = () => {
        if (disabled) return;
        if (isFull) return onFinished(null);

        isStarted = true;
        if (!canvasContext) {
            wheelInit();
        }
        if (timerHandle === 0) {
            setWinSegment();
            maxSpeed = Math.PI / segments.length;
            frames = 0;
            timerHandle = setInterval(onTimerTick, timerDelay);
            playSound();
        }
    };
    const onTimerTick = () => {
        frames++;
        draw();
        let progress = 0;
        let finished = false;
        if (frames < upTime) {
            progress = frames / upTime;
            angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2);
        } else {
            if (winningSegmentIndex !== undefined) {
                if (angleDelta <= 0.03) {
                    if (currentSegmentIndex === winningSegmentIndex && frames > segments.length) {
                        progress = 1;
                        // console.log(angleCurrent, angleDelta);
                    }
                    // console.log(angleCurrent, angleDelta);
                } else {
                    progress = frames / downTime;
                    angleDelta =
                        maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
                }
            } else {
                progress = frames / downTime;
                angleDelta =
                    maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
            }
            if (progress >= 1) finished = true;
        }

        angleCurrent += angleDelta;
        while (angleCurrent >= Math.PI * 2) angleCurrent -= Math.PI * 2;
        if (finished) {
            onFinished(currentSegment);
            clearInterval(timerHandle);
            timerHandle = 0;
            angleDelta = 0;
            audio.pause();
        }
    };

    const playSound = async () => {
        // Sound
        await audio.play();
    };

    const wheelDraw = () => {
        clear();
        drawWheel();
        drawNeedle();
    };

    const draw = () => {
        clear();
        drawWheel();
        drawNeedle();
    };

    const drawSegment = (key, lastAngle, angle) => {
        if (!canvasContext) {
            return false;
        }
        const ctx = canvasContext;
        const item = segments[key];
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(centerX, centerY);
        ctx.arc(centerX, centerY, canvasSize, lastAngle, angle, false);
        ctx.lineTo(centerX, centerY);
        ctx.closePath();
        ctx.fillStyle = item.color;
        ctx.fill();
        ctx.stroke();
        ctx.save();
        ctx.translate(centerX, centerY);
        ctx.rotate((lastAngle + angle) / 2 + (key === 0 ? Math.PI / segments.length / 2 : 0));
        ctx.fillStyle = textColor;
        ctx.font = `bold ${_fontSize}px ` + fontFamily;

        // draw multi line
        const each_line_characters = eachLine;
        const lines = Math.ceil(item.text.length / each_line_characters);
        let start = 0;
        const lineHeight = fontSize * 2.5;
        let startY = lines > 1 ? Math.floor((lines - 1) * lineHeight / 2) * -1 : 0;
        while (true) {
            const drawText = item.text.substring(start, start + each_line_characters);
            if (drawText.length === 0) break;

            ctx.fillText(drawText, canvasSize / 2 + _textRadius, startY);

            if (drawText.length < each_line_characters){
                break;
            }
            start += each_line_characters;
            startY += lineHeight;
        }

        if (images[key]) {
            const width = (canvasDimension / 500) * iconWidth;
            const height = (canvasDimension / 500) * iconHeight;

            ctx.drawImage(
                images[key],
                canvasSize / 2 + _imageRadius,
                -height / 2,
                width,
                height
            );
        }

        ctx.restore();
    };

    const drawWheel = () => {
        if (!canvasContext) {
            return false;
        }
        const ctx = canvasContext;
        let lastAngle = angleCurrent;
        const len = segments.length;
        const PI2 = Math.PI * 2;
        ctx.lineWidth = 1;
        ctx.strokeStyle = primaryColor;
        ctx.textBaseline = "middle";
        ctx.textAlign = align;
        ctx.font = "1em " + fontFamily;
        for (let i = 1; i <= len; i++) {
            const angle = PI2 * (i / len) + angleCurrent + Math.PI / len;
            drawSegment(i - 1, lastAngle, angle);
            lastAngle = angle;
        }

        // Draw outer circle
        ctx.beginPath();
        ctx.arc(centerX, centerY, canvasSize, 0, PI2, false);
        ctx.closePath();

        ctx.lineWidth = 10;
        ctx.strokeStyle = primaryColor;
        ctx.stroke();
    };

    const drawNeedle = () => {
        // if (!canvasContext) {
        //     return false;
        // }
        // const ctx = canvasContext;

        const change = angleCurrent + Math.PI / 2;
        let i =
            segments.length -
            Math.floor((change / (Math.PI * 2)) * segments.length) -
            1;
        if (i < 0) i = i + segments.length;
        // ctx.textAlign = "center";
        // ctx.textBaseline = "middle";
        // ctx.fillStyle = primaryColor;
        // ctx.font = "bold 1.5em " + fontFamily;

        // change needle
        if (currentSegmentIndex !== i) {
            currentSegmentIndex = i;
            currentSegment = segments[i].text;
        }
        // isStarted &&
        // ctx.fillText(currentSegment, centerX + 10, centerY + canvasSize + 50);
    };
    const clear = () => {
        if (!canvasContext) {
            return false;
        }
        canvasContext.clearRect(0, 0, canvasDimension, canvasDimension);
    };
    return (
        <div id={wheelId.current} style={{ position: "relative", overflow: "hidden" }}>
            <canvas
                id={canvasId.current}
                width={dimension}
                height={dimension}
                style={{ margin: "0 auto" }}
            />
            <div className="icon-back"></div>
            <div className="icon" onClick={() => spin()}/>
        </div>
    );
};
export default Wheel;