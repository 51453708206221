import Wheel from "../../components/wheel";
import React, {useEffect, useState} from "react";
import Loader from "../../components/Loader";
import {ColorLens} from "@mui/icons-material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import EditSegmentItem from "../../components/EditSegmentItem";
import {FormControlLabel, FormLabel, Radio, RadioGroup, Slider} from "@mui/material";
import {Apis} from "../../api";
import {toast} from "react-toastify";

function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}


function Home() {
    const [segments, setSegments] = useState([]);
    const [tab, setTab] = React.useState(0);
    const [align, setAlign] = useState("center");
    const [textRadius, setTextRadius] = useState(10);
    const [iconRadius, setIconRadius] = useState(90);
    const [labelSize, setLabelSize] = useState(20);
    const [iconWidth, setIconWidth] = useState(30);
    const [iconHeight, setIconHeight] = useState(30);
    const [eachLine, setEachLine] = useState(20);
    const [winDlgText, setWinDlgText] = useState("");

    useEffect(() => {
        loadConfig();
    }, []);

    const loadConfig = () => {
        Apis.getConfig().then((res) => {
            if (res.success && res.data.success) {
                const config = res.data.data;
                setSegments(config.segments);
                setAlign(config.align);
                setTextRadius(config.textRadius);
                setIconRadius(config.iconRadius);
                setLabelSize(config.labelSize);
                setIconWidth(config.iconWidth);
                setIconHeight(config.iconHeight);
                setWinDlgText(config.winDlgText);
                setEachLine(config.eachLine ?? 20);
            }
        });
    }

    const onChangeItem = (index, item) => {
        const newSegments = Object.assign([], segments);
        newSegments[index] = item;
        setSegments(newSegments)
    }

    const onDeleteItem = (index) => {
        const newSegments = segments.slice(0, index).concat(segments.slice(index + 1));
        setSegments(newSegments);
    }

    const onFinished = (winner) => {
        console.log(winner)
    }

    const onAddNew = () => {
        const newSegment = {
            imageUrl: "",
            color: "#000000",
            text: '',
            noSelect: false,
            percent: 0
        }
        setSegments([...segments, newSegment]);
    }

    const onSave = async () => {
        // TODO API save call
        const payload = {
            data: {
                segments,
                align,
                textRadius,
                iconRadius,
                labelSize,
                iconWidth,
                iconHeight,
                winDlgText,
                eachLine,
            }
        }

        const res = await Apis.updateConfig(payload);
        if (res.success && res.data.success) {
            toast.success("Successfully updated!");
        } else {
            toast.error("Failed!");
        }
    }

    const onReset = () => {
        loadConfig();
    }

    if (segments.length === 0) {
        return (<div className="flex w-full min-h-screen items-center justify-center">
            <Loader/>
        </div>);
    }

    return (
        <div className="flex w-full min-h-screen flex-wrap lg:flex-nowrap">
            <div className="my-auto mx-auto">
                <div style={{display: "none"}}>
                    {
                        segments.map((s, index) => <img key={index} id={`segment-image-${index}`} src={s.imageUrl}
                                                        alt="" width={30} height={30}/>)
                    }
                </div>
                <Wheel
                    segments={segments}
                    onFinished={(winner) => onFinished(winner)}
                    primaryColor='black'
                    isOnlyOnce={false}
                    iconWidth={iconWidth}
                    iconHeight={iconHeight}
                    upDuration={50}
                    downDuration={120}
                    fontFamily='Arial'
                    fontSize={labelSize}
                    textColor='white'
                    align={align}
                    textRadius={textRadius}
                    imageRadius={iconRadius}
                    eachLine={eachLine}
                />
            </div>
            <div className="flex-grow w-full bg-white rounded-xl sm:py-8 py-4 sm:px-4 px-2 sm:my-4 my-2">
                <div className="flex justify-between flex-wrap gap-4">
                    <div className="flex items-end gap-2">
                        <ColorLens color="white" fontSize="large"/>
                        <label className="text-3xl">Customize</label>
                    </div>
                    <div className="flex items-center gap-4">
                        <button className="w-full px-8 rounded-xl py-1 bg-gray-500 text-white text-xl" onClick={onReset}>Reset</button>
                        <button className="w-full px-8 rounded-xl py-1 bg-blue-500 text-white text-xl" onClick={onSave}>Save</button>
                    </div>
                </div>
                <Box sx={{width: '100%', marginTop: "20px"}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tab} onChange={(e, t) => setTab(t)} aria-label="basic tabs example">
                            <Tab label="Items"/>
                            <Tab label="Settings"/>
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={tab} index={0}>
                        <div className="overflow-y-auto" style={{height: "calc(100vh - 200px)"}}>
                            {
                                segments.map((s, index) => <EditSegmentItem key={index} index={index} item={s}
                                                                            onChange={(item) => onChangeItem(index, item)}
                                                                            onDelete={() => onDeleteItem(index)}/>)
                            }
                            <button className="w-full h-12 mt-8 bg-blue-500 text-white text-3xl" onClick={onAddNew}> +</button>
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={tab} index={1}>
                        <div className="p-2 md:p-4">
                            <div className="mt-4">
                                <FormLabel>Text Font Size: </FormLabel>
                                <input type="number" className="border-2 text-lg w-24 px-1 mx-1" value={labelSize}
                                       onChange={(e) => {
                                           if (e.target.value) {
                                               const size = Number(e.target.value)
                                               if (size) {
                                                   setLabelSize(size);
                                               }
                                           }
                                       }}/>px
                            </div>
                            <div className="mt-4">
                                <FormLabel>Number of characters on one line: </FormLabel>
                                <input type="number" className="border-2 text-lg w-24 px-1 mx-1" value={eachLine}
                                       onChange={(e) => {
                                           if (e.target.value) {
                                               const size = Number(e.target.value)
                                               if (size && size > 10) {
                                                   setEachLine(size);
                                               }
                                           }
                                       }}/>
                            </div>
                            <div className="mt-4">
                                <FormLabel>Text Align:</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={align}
                                    onChange={(e, v) => setAlign(v)}
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value="left" control={<Radio/>} label="Left"/>
                                    <FormControlLabel value="center" control={<Radio/>} label="Center"/>
                                    <FormControlLabel value="right" control={<Radio/>} label="Right"/>
                                </RadioGroup>
                            </div>
                            <div className="mt-4">
                                <FormLabel>Text Position:</FormLabel>
                                <Slider
                                    className="mt-12"
                                    aria-label="Custom marks"
                                    value={textRadius}
                                    onChange={(e, v) => setTextRadius(v)}
                                    getAriaValueText={(val) => `${val}`}
                                    max={100}
                                    min={10}
                                    step={10}
                                    valueLabelDisplay="on"
                                />
                            </div>
                            <div className="mt-4">
                                <FormLabel>Icon Position:</FormLabel>
                                <Slider
                                    className="mt-12"
                                    aria-label="Icon position"
                                    value={iconRadius}
                                    onChange={(e, v) => setIconRadius(v)}
                                    getAriaValueText={(val) => `${val}`}
                                    max={100}
                                    min={10}
                                    step={10}
                                    valueLabelDisplay="on"
                                />
                            </div>
                            <div className="mt-4">
                                <FormLabel>Icon Size: </FormLabel>
                                <label className="mr-4">
                                    width:
                                    <input type="number" className="border-2 text-lg w-24 px-1 mx-1" value={iconWidth}
                                           onChange={(e) => {
                                               if (e.target.value) {
                                                   const size = Number(e.target.value)
                                                   if (size) {
                                                       setIconWidth(size);
                                                   }
                                               }
                                           }}/>px
                                </label>
                                <label>
                                    height:
                                    <input type="number" className="border-2 text-lg w-24 px-1 mx-1" value={iconHeight}
                                           onChange={(e) => {
                                               if (e.target.value) {
                                                   const size = Number(e.target.value)
                                                   if (size) {
                                                       setIconHeight(size);
                                                   }
                                               }
                                           }}/>px
                                </label>
                            </div>
                            <div className="mt-4">
                                <div>Dialog Text: </div>
                                <textarea className="h-[200px] border rounded-xl w-full mt-2 p-4" onChange={(e) => setWinDlgText(e.target.value)} >{winDlgText}</textarea>
                            </div>
                        </div>
                    </CustomTabPanel>
                </Box>
            </div>
        </div>
    );
}

export default Home;
