import {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  getAccessToken,
  removeAccessTokenFromStorage,
  setAccessTokenToStorage,
} from "./index";
import { Apis } from "../api";

const defaultContext = {
  loading: true,
  user: null,
  setUser: () => null,
  setUserInfo: () => null,
  resetUserInfo: () => null,
};

const AuthContext = createContext(defaultContext);

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  function setUserInfo(user) {
    // set axios header
    if (user.accessToken) {
      Apis.setAuthToken(user.accessToken);
      setAccessTokenToStorage(user.accessToken);
    }

    // set user on context
    setUser(user);
  }

  function resetUserInfo() {
    removeAccessTokenFromStorage();
    Apis.setAuthToken("");

    setUser(null);
  }

  useEffect(() => {
    const accessToken = getAccessToken();
    if (accessToken) {
      // fetch me
      Apis.me()
        .then((res) => {
          if (res.success && res.data.success) {
            // Success
            const user = res.data.data;
            setUserInfo(user);
            setLoading(false);
          } else {
            // Failed
            setUser(null);
            setLoading(false);
            removeAccessTokenFromStorage();
          }
        })
        .catch(() => {
          // Call failed
          setUser(null);
          setLoading(false);
          removeAccessTokenFromStorage();
        });
    } else {
      setLoading(false);
    }
  }, []);

  const values = {
    loading,
    user,
    setUser,
    setUserInfo,
    resetUserInfo,
  };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
export const useAuthContext = () => useContext(AuthContext);
