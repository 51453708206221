import React from "react";

const Loader = () => {
  return (
    <div className="flex justify-center items-center h-full mt-10">
      <div className="w-16 h-16 relative">
        <div className="absolute top-0 left-0 w-full h-full rounded-full border-t-4 border-b-4 border-gray-900 animate-spin"></div>
        <div className="absolute top-0 left-0 w-full h-full rounded-full border-t-4 border-b-4 border-blue-500 animate-pulse"></div>
      </div>
    </div>
  );
};

export default Loader;
