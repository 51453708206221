import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../auth/context";
import SidebarItem from "./SidebarItem";
import {Attractions, Checklist} from "@mui/icons-material";

function AdminSidebar() {
  const navigate = useNavigate();
  const { resetUserInfo } = useAuthContext();
  const location = useLocation();
  const { pathname } = location;

  function logout() {
    resetUserInfo();
    navigate("/login");
  }

  return (
    <div className="flex flex-col shadow-lg bg-white dark:bg-darkwhite-80 min-w-[260px] py-10 min-h-full">
      <img
        className="w-[160px] h-[120px] mx-auto object-contain"
        src="/logo.png"
        alt=""
      />

      <div className="mx-auto mb-10 flex-grow">
        <SidebarItem
          currentPath={pathname}
          path="/admin"
          label={"Customize"}
          icon={<Attractions stroke="currentColor" />}
        />
        <SidebarItem
          currentPath={pathname}
          path="/admin/links"
          label={"Link List"}
          icon={<Checklist stroke="currentColor" />}
        />
      </div>

       <button className="text-xl" onClick={logout}>Logout</button>
    </div>
  );
}

export default AdminSidebar;
