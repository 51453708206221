import Wheel from "../../components/wheel";
import {useEffect, useState} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead, TablePagination,
    TableRow, TextField
} from "@mui/material";
import {Apis} from "../../api";
import {toast} from "react-toastify";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


function LinkList() {
    const [links, setLinks] = useState([]);
    const [page, setPage] = useState(0);
    const [openDlg, setOpenDlg] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(null);

    const getLinks = async () => {
        const res = await Apis.getLinks(page);
        if (res.data.success) {
            setPage(res.data.page);
            setLinks(res.data.items);
        }
    }

    useEffect(() => {
        getLinks().then();
    }, [refresh, page]);

    const onGenerate = async (name, max) => {
        const res = await Apis.generateLink({linkName: name.toLowerCase(), max});
        if (res.success && res.data.success) {
            toast.success("Successfully generated!");
            setRefresh(!refresh);
        } else {
            toast.error(res.data.message);
        }
    }

    const onDelete = async (id) => {
        setOpenConfirmDelete(null)
        const res = await Apis.deleteLink({id});
        if (res.success && res.data.success) {
            toast.success("Successfully deleted!");
            setRefresh(!refresh);
        } else {
            toast.error(res.data.message);
        }
    }

    return (
        <div className="my-8 mx-2 sm:mx-4">
            <div className="mb-2">
                <button className="px-8 rounded-xl py-1 bg-blue-500 text-white text-xl" onClick={() => setOpenDlg(true)}>Generate link</button>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="center">Max Spins</StyledTableCell>
                            <StyledTableCell align="center">Done</StyledTableCell>
                            <StyledTableCell align="center">Wins</StyledTableCell>
                            <StyledTableCell align="center">Last Updated</StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {links.map((row, key) => (
                            <StyledTableRow key={key}>
                                <StyledTableCell component="th" scope="row"><a href={`/${row.linkName}`} target="_blank">{row.linkName}</a></StyledTableCell>
                                <StyledTableCell align="center">{row.max}</StyledTableCell>
                                <StyledTableCell align="center">{row.done}</StyledTableCell>
                                <StyledTableCell align="center" dangerouslySetInnerHTML={{ __html: row.wins.join(",<br/>") }}></StyledTableCell>
                                <StyledTableCell align="center">{moment(row.updatedAt).format("YYYY/MM/DD HH:mm")}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <button className="px-4 rounded-xl py-1 bg-red-500 text-white text-sm" onClick={() => setOpenConfirmDelete(row._id)}>Delete</button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={links.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, v) => setPage(v)}
                onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
            />

            <Dialog
                open={openDlg}
                onClose={() => setOpenDlg(false)}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const name = formJson.name;
                        const max = formJson.max;
                        onGenerate(name, max).then();
                        setOpenDlg(false);
                    },
                }}
            >
                <DialogTitle>Generate Link</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Link Name"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        required
                        margin="dense"
                        id="max"
                        name="max"
                        label="Link Max Spins"
                        type="number"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDlg(false)}>Cancel</Button>
                    <Button type="submit">Generate</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={!!openConfirmDelete}
                onClose={() => setOpenConfirmDelete(null)}
            >
                <DialogTitle>
                    Are you sure to delete this link?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDelete(null)}>Cancel</Button>
                    <Button onClick={() => onDelete(openConfirmDelete)} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default LinkList;
