import {Checkbox, FormControlLabel, IconButton} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ColorPicker from "./ColorPicker";
import {Apis} from "../api";
import {FileUpload} from "../utils/fileupload";

function EditSegmentItem({item, index, onChange, onDelete}) {
    const handleFile = async (event) => {
        const res = await FileUpload(event.target.files[0]);
        console.log(res)
        if (res) {
            onChange({...item, imageUrl: res});
        }
    };

    return <div className="flex items-center justify-between border-b-2 pb-2 mt-4">
        <div className="flex-grow">
            <div className="flex items-center gap-2">
                <ColorPicker color={item.color} onChangeColor={(v) => onChange({...item, color: v})}/>
                <input className="border-2 text-lg px-1 flex-grow min-w-32" type="text"
                       onChange={(e) => onChange({...item, text: e.target.value})} value={item.text}/>
                <div className="relative">
                    <img width={30} height={30} src={item.imageUrl} alt=""/>
                    <div className="absolute left-0 right-0 top-0 bottom-0 opacity-0">
                        <input type="file" id="file-input" name="ImageStyle" accept="image/png,image/svg,image/jpeg" onChange={handleFile}/>
                    </div>
                </div>
            </div>
            <div className="flex items-center mt-2">
                <span className="mr-4">Select:</span>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={item.noSelect}
                            onChange={(e) => onChange({
                                ...item,
                                noSelect: e.target.checked
                            })}/>
                    }
                    label="Never Or"
                />
                <FormControlLabel
                    control={
                        <input
                            type="number"
                            className="border-2 text-lg w-24 px-1 mx-1"
                            disabled={item.noSelect}
                            value={item.percent}
                            onChange={(e) => {
                                if (e.target.value && Number(e.target.value) > 0) {
                                    onChange({
                                        ...item,
                                        percent: Number(e.target.value) ?? 0,
                                    });
                                }
                            }}/>
                    }
                    label="%"/>
            </div>
        </div>
        <div className="sm:px-2">
            <IconButton aria-label="delete" size="normal" onClick={onDelete}>
                <DeleteIcon fontSize="inherit" color="error"/>
            </IconButton>
        </div>
    </div>
}

export default EditSegmentItem;